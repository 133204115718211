'use strict';

import http from '@/utils/http';

export default {
  // 分页列表查询
  pageUrl: '/wechat/wechatAccountChannel/page',

  // 根据appId查询权限集合
  // queryAuthorization(code , params) {
  //   return http.get(`/wechat/accountFuncScopeCategory/getFuncScopeCategoryList` ,{params,code});
  // },

  queryAuthorization:
    '/wechat/accountFuncScopeCategory/getFuncScopeCategoryList',

  // 设置未默认账户
  authorizationstatus(code, params) {
    return http.get(`/wechat/wechatAccountChannel/setAccountDefault`, {
      params,
      code,
    });
  },

  // 更新微信公众号
  refreshAccountStatus(code) {
    return http.get(`/wechat/wechatAccountChannel/refreshAccountStatus`, {
      code,
    });
  },

  // /wechat/thirdPartyAuthorization/getPreAuthCode
  thirdPartyAuthorization(code) {
    return http.get(`/wechat/thirdPartyAuthorization/getPreAuthCode`, { code });
  },

  // 绑定微信公众号
  // wxUrl https://mp.weixin.qq.com/cgi-bin/componentloginpage?component_appid=xxxx&pre_auth_code=xxxxx&redirect_uri=xxxx&auth_type=xxx
  // wxbindNotifyUrl(code, componentAppid, preAuthCode) {
  //   return http.get(
  //     `https://mp.weixin.qq.com/cgi-bin/componentloginpage?component_appid=${componentAppid}&pre_auth_code=${preAuthCode}&redirect_uri=https://v3-uat.issmart.com.cn/#/system/enterprise&auth_type=3`,
  //     { code }
  //   );
  // },
};
