<template>
  <a-tabs v-model:activeKey="activeKey" class="iss-tab">
    <a-tab-pane key="info" tab="企业信息">
      <div class="iss-main" v-if="activeKey === 'info'">
        <a-form
          :colon="false"
          :label-col="{ span: 3 }"
          :wrapper-col="{ span: 15 }"
          :validate-trigger="['change', 'blur']"
        >
          <a-form-item label="企业名称" v-bind="infoRules.name">
            <a-input v-model:value="info.name" placeholder="请输入企业名称" />
          </a-form-item>
          <a-form-item label="地址" v-bind="infoRules.address">
            <a-textarea v-model:value="info.address" placeholder="请输入地址" />
          </a-form-item>
          <a-form-item label="电话" v-bind="infoRules.phone">
            <a-input v-model:value="info.phone" placeholder="请输入电话号码" />
          </a-form-item>
          <a-form-item label="邮箱" v-bind="infoRules.email">
            <a-input v-model:value="info.email" placeholder="请输入邮箱地址" />
          </a-form-item>
          <a-form-item
            label="LOGO图"
            extra="请上传大小不超过1M，格式为JPG，JPEG或PNG的图片"
            v-bind="infoRules.logo"
          >
            <iss-image-upload
              v-model:value="info.logo"
              list-type="picture-card"
            />
          </a-form-item>
          <a-form-item :wrapper-col="{ span: 15, offset: 3 }">
            <a-space :size="12">
              <a-button type="primary" @click="handleClickByInfo">
                保存
              </a-button>
              <a-button @click="handleClickByInfoReset">重置</a-button>
            </a-space>
          </a-form-item>
        </a-form>
      </div>
    </a-tab-pane>
    <a-tab-pane key="channel" tab="服务渠道" forceRender>
      <div class="iss-main main-extra">
        <!-- v-model:active-key="activeKey" -->
        <a-tabs tab-position="left" v-model:active-key="activeServeKey">
          <a-tab-pane key="email" tab="邮件配置">
            <a-form layout="vertical" class="iss-form">
              <a-card title="基本配置">
                <a-row :gutter="20">
                  <a-col :xs="24" :md="12">
                    <a-form-item
                      label="SMTP服务器地址"
                      v-bind="emailRules.stmpServer"
                    >
                      <a-input
                        v-model:value="email.stmpServer"
                        placeholder="请输入SMTP服务器地址"
                      />
                    </a-form-item>
                  </a-col>
                  <a-col :xs="24" :md="12">
                    <a-form-item
                      label="SMTP服务器端口"
                      v-bind="emailRules.stmpPort"
                    >
                      <a-input
                        v-model:value="email.stmpPort"
                        placeholder="请输入SMTP服务器端口"
                      />
                    </a-form-item>
                  </a-col>
                </a-row>
                <a-row :gutter="20">
                  <a-col :xs="24" :md="12">
                    <a-form-item label="发送账号" v-bind="emailRules.account">
                      <a-input
                        v-model:value="email.account"
                        placeholder="请输入邮件发送账号"
                      />
                    </a-form-item>
                  </a-col>
                  <a-col :xs="24" :md="12">
                    <a-form-item label="账号密码" v-bind="emailRules.password">
                      <a-input-password
                        v-model:value="email.password"
                        placeholder="请输入账号密码"
                      />
                    </a-form-item>
                  </a-col>
                </a-row>
                <a-row :gutter="20">
                  <a-col :xs="24" :md="12">
                    <a-form-item label="是否需要加密连接(SSL)">
                      <a-switch
                        v-model:checked="email.isSsl"
                        checked-children="是"
                        un-checked-children="否"
                      />
                    </a-form-item>
                  </a-col>
                  <a-col :xs="24" :md="12">
                    <a-form-item label="是否忽视TLS">
                      <a-switch
                        v-model:checked="email.ignoreTls"
                        checked-children="是"
                        un-checked-children="否"
                      />
                    </a-form-item>
                  </a-col>
                </a-row>
              </a-card>
              <a-card title="发送人配置">
                <a-form-item label="邮件发送人" v-bind="emailRules.senderName">
                  <a-input
                    v-model:value="email.senderName"
                    placeholder="请输入邮件发送人"
                  />
                </a-form-item>
              </a-card>
              <a-card title="退订管理">
                <a-form-item
                  label="退订链接"
                  v-bind="emailRules.unsubscribeUrl"
                >
                  <a-input
                    v-model:value="email.unsubscribeUrl"
                    placeholder="请输入退订链接"
                  />
                </a-form-item>
              </a-card>
              <a-form-item>
                <a-space :size="12">
                  <a-button type="primary" @click="handleClick">
                    保存
                  </a-button>
                  <a-button @click="handleClickByReset">重置</a-button>
                </a-space>
              </a-form-item>
            </a-form>
          </a-tab-pane>
          <a-tab-pane key="sms" tab="短信配置" forceRender>
            <a-form layout="vertical">
              <a-card title="基本配置">
                <!--                <a-form-item label="通道" v-bind="smsRules.id">-->
                <!--                  <a-select-->
                <!--                    v-model:value="sms.id"-->
                <!--                    placeholder="请选择通道"-->
                <!--                    :getPopupContainer="triggerNode => triggerNode.parentNode"-->
                <!--                  >-->
                <!--                    <a-select-option-->
                <!--                      v-for="item in SMSChannelList"-->
                <!--                      :key="item.id"-->
                <!--                      :value="item.id"-->
                <!--                    >-->
                <!--                      {{ item.smsChannel }}-->
                <!--                    </a-select-option>-->
                <!--                  </a-select>-->
                <!--                </a-form-item>-->
                <a-form-item label="通道" v-bind="smsRules.id">
                  <a-input
                    disabled
                    v-model:value="sms.smsChannel"
                    placeholder="请选择通道"
                  >
                  </a-input>
                </a-form-item>
                <a-form-item label="短信签名" v-bind="smsRules.signer">
                  <a-select
                    v-model:value="sms.signer"
                    placeholder="请选择短信签名"
                    option-label-prop="label"
                    :open="open"
                    :getPopupContainer="triggerNode => triggerNode.parentNode"
                    ref="groupSelect"
                  >
                    <template #dropdownRender="{ menuNode: menu }">
                      <v-nodes :vnodes="menu" />
                      <a-divider style="margin: 4px 0" />
                      <div class="sign-group">
                        <a-input
                          v-model:value="groupName"
                          class="group-input"
                          placeholder="新建短信签名"
                          ref="groupInput"
                        />
                        <a
                          class="ml-10"
                          @mousedown="e => e.preventDefault()"
                          @click="handleClickByAddGroup"
                        >
                          <PlusCircleOutlined />
                        </a>
                      </div>
                    </template>
                    <a-select-option
                      v-for="item in SMSSignList"
                      :key="item.id"
                      :value="item.signSms"
                      :label="item.signSms"
                      @click="open = false"
                    >
                      <div class="flex">
                        {{ item.signSms }}
                        <a @click="handleClickByDeleteGroup($event, item.id)">
                          <MinusCircleOutlined />
                        </a>
                      </div>
                    </a-select-option>
                  </a-select>
                </a-form-item>
                <!--     todo 增加一个开关 internationalSMS  v-bind="smsRules.code"  -->
                <a-form-item label="是否启用国际短信">
                  <!--    @change="wechatOpenChange"         <a-switch v-model:checked="formState.enabled" />-->
                  <a-switch
                    v-model:checked="sms.internationalMessageStatus"
                    :disabled="sms.smsDisabled"
                    checked-children="启用"
                    un-checked-children="不启用"
                  />
                  <div class="font-color font-size">
                    如需开启国际短信，请联系平台相关人员进行办理
                  </div>
                </a-form-item>
                <a-form-item
                  label="通道"
                  v-bind="smsRules.id"
                  style="opacity: 0; user-select: none"
                >
                  <a-input
                    v-model:value="sms.id"
                    placeholder="请选择通道"
                    :getPopupContainer="triggerNode => triggerNode.parentNode"
                  >
                    <span>{{ sms.smsChannel }}</span>
                  </a-input>
                </a-form-item>
              </a-card>
              <a-form-item>
                <a-space :size="12" class="iss-button">
                  <a-button type="primary" @click="handleClick">
                    保存
                  </a-button>
                  <a-button @click="handleClickByReset">重置</a-button>
                </a-space>
              </a-form-item>
            </a-form>
          </a-tab-pane>
          <a-tab-pane key="wechat" tab="微信配置">
            <div class="refresh-btn">
              <LinkOutlined
                class="mr-10"
                v-if="!loading"
                @click="bindingWxFun"
              />
              <a-spin class="mr-10" size="small" v-else />
              <span class="margin-right-50" @click="bindingWxFun"
                >绑定微信公众号</span
              >
              <div>
                <SyncOutlined class="mr-10" @click="updateWxFun" />
                <a-spin class="mr-10" size="small" />
                <span class="updateWx" @click="updateWxFun"
                  >更新公众号状态</span
                >
              </div>
            </div>

            <div class="iss-grid-wrap mt-16">
              <!-- 发送记录弹框 -->
              <a-modal
                :title="`已获权限`"
                v-model:visible="recordModalVisible"
                width="60%"
                :centered="true"
                :destroyOnClose="true"
              >
                <div class="modal-container">
                  <grid
                    ref="iss-grid"
                    :columns="recordColumns"
                    :code="$route.path"
                    :url="urlModalPath"
                    :url-params="{
                      appId: appId,
                    }"
                    :search="search"
                    :scroll="{ x: 1200, y: 600 }"
                    :pagination="false"
                  >
                    <template #name="{ text }">
                      <span v-if="!text">-</span>
                      <span v-else>{{ text }}</span>
                    </template>

                    <template #enabled="{ text }">
                      <div>
                        <span v-if="text === true"
                          ><img
                            src="../../../assets/images/checkMarkIcon.png"
                            alt=""
                            width="15px"
                            height="15px"
                        /></span>
                        <span v-else-if="text === false"
                          ><img
                            src="../../../assets/images/wrongNumberIcon.png"
                            alt=""
                            width="15px"
                            height="15px"
                        /></span>
                      </div>
                    </template>

                    <template #desc="{ text }">
                      <span v-if="!text">-</span>
                      <span v-else>{{ text }}</span>
                    </template>
                  </grid>
                </div>
                <template #footer>
                  <a-space>
                    <a-button @click="recordModalVisible = false">
                      取消
                    </a-button>
                  </a-space>
                </template>
              </a-modal>
              <grid
                ref="gridRef"
                :columns="columns"
                :code="$route.path"
                :url="urlPath"
                :search="search"
                :scroll="{ x: 1200, y: 1200 }"
              >
                <template #nickName="{ record }">
                  <div class="uofficial_Account flex">
                    <div class="img-box">
                      <img v-if="record.headImg" :src="record.headImg" alt="" />
                      <img
                        v-else
                        src="../../../assets/images/headImg.png"
                        alt=""
                      />
                    </div>
                    <div class="flex-1">{{ record.nickName }}</div>
                  </div>
                </template>

                <template #isOpen="{ record }">
                  <div style="color: #5979f8; cursor: pointer">
                    <span v-if="record.isOpen === true">默认</span>
                    <span
                      v-else-if="record.isOpen === false"
                      @click="handleAuthorizationstatus(record)"
                      >设为默认</span
                    >
                  </div>
                </template>

                <template #queryAuthorization="{ record }">
                  <div
                    style="color: #5979f8; cursor: pointer"
                    @click="handleQueryAuthorization(record)"
                  >
                    查看
                  </div>
                </template>

                <template #authorizationStatus="{ record }">
                  <div>
                    <span
                      v-if="record.authorizationStatus === true"
                      class="center-content"
                      ><img
                        src="../../../assets/images/wechatIconGreen.png"
                        alt=""
                        width="9px"
                        height="9px"
                      />&nbsp;已绑定</span
                    >
                    <span
                      v-else-if="record.authorizationStatus === false"
                      class="center-content"
                      ><img
                        src="../../../assets/images/wechatIconRed.png"
                        alt=""
                        width="9px"
                        height="9px"
                      />&nbsp;未绑定</span
                    >
                  </div>
                </template>

                <template #accountType="{ record }">
                  <span v-if="record.accountType == 1">订阅号</span>
                  <span v-else-if="record.accountType == 2">服务号</span>
                </template>

                <template #authenticated="{ record }">
                  <span v-if="record.authenticated === true">已认证</span>
                  <span v-else-if="record.authenticated === false">未认证</span>
                </template>
              </grid>
            </div>

            <div>
              <a-modal
                v-model:visible="visibleBind"
                :title="``"
                width="30%"
                centered="true"
                @ok="handleOk"
                @cancel="handleCancel"
              >
                <div class="modal-content-center">
                  <div
                    style="
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      margin-bottom: 10px;
                      margin-top: 30px;
                    "
                  >
                    <img
                      src="../../../assets/images/warm.png"
                      alt=""
                      width="13px"
                      height="13px"
                    />
                    &nbsp;
                    <span> 点击确定将跳转到腾讯微信验证页面进行授权</span>
                  </div>
                  <div style="color: red">
                    注意：授权完成后请勿关闭页面或进出系统，否则将授权失效
                  </div>
                </div>
                <template #footer>
                  <a-space>
                    <a-button class="cancle_sty" @click="visibleBind = false">
                      <span>取消</span>
                    </a-button>
                  </a-space>
                  <a-space>
                    <a-button key="submit" type="primary" @click="handleOk">
                      确定
                    </a-button>
                  </a-space>
                </template>
              </a-modal>
            </div>
          </a-tab-pane>
        </a-tabs>
      </div>
    </a-tab-pane>
    <a-tab-pane key="agreement" tab="协议版权">
      <proto-colconfiguration
        v-if="activeKey === 'agreement'"
      ></proto-colconfiguration>
    </a-tab-pane>
  </a-tabs>
</template>

<script>
import { onMounted, onUnmounted, reactive, ref, toRaw, toRefs } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import {
  Card,
  Col,
  Divider,
  Form,
  message,
  Row,
  Select,
  Space,
  Switch,
  Tabs,
  Modal,
} from 'ant-design-vue';
import {
  PlusCircleOutlined,
  MinusCircleOutlined,
  SyncOutlined,
  LinkOutlined,
} from '@ant-design/icons-vue';
import IssImageUpload from '@/components/imageUpload';
import { mobileRegExp } from '@/utils/validation';
import enterpriseApi from '@/api/enterprise';
import ProtoColconfiguration from './protocolconfiguration';
import Grid from '@/components/grid';
import wechatConfig from '@/api/wechatConfig.js';

export default {
  components: {
    AModal: Modal,
    ATabs: Tabs,
    ATabPane: Tabs.TabPane,
    AForm: Form,
    AFormItem: Form.Item,
    ASpace: Space,
    ACard: Card,
    ASwitch: Switch,
    ARow: Row,
    ACol: Col,
    ASelect: Select,
    ASelectOption: Select.Option,
    ADivider: Divider,
    PlusCircleOutlined,
    MinusCircleOutlined,
    VNodes: (_, { attrs }) => attrs.vnodes,
    IssImageUpload,
    ProtoColconfiguration,
    Grid,
    SyncOutlined,
    LinkOutlined,
  },
  setup() {
    const gridRef = ref();
    const required = { required: true, whitespace: true, message: '不能为空' };
    const route = useRoute();
    const store = useStore();
    // const activeKey = ref('info');
    const activeKey = ref(route.query.key === 'channel' ? 'channel' : 'info');
    const state = reactive({
      // activeServeKey: 'email',
      activeServeKey: route.query.key === 'channel' ? 'wechat' : 'email',
      SMSChannelList: [],
      SMSSignList: [],
      open: false,
      groupName: '',
      id: '',
      appId: '',
      updateLoading: false,
    });
    const recordModalVisible = ref(false);
    const visibleBind = ref(false);
    const groupSelect = ref();
    const groupInput = ref();
    let temp = {
      info: {},
      email: {},
      sms: {},
      wechat: {},
    };

    const info = reactive({});
    const infoForm = Form.useForm(info, {
      name: [required],
      address: [required],
      phone: [
        required,
        { pattern: mobileRegExp, message: '请输入正确的电话号码' },
      ],
      email: [required, { type: 'email', message: '请输入正确的邮箱地址' }],
      logo: [required],
    });
    const email = reactive({});
    const emailForm = Form.useForm(email, {
      stmpServer: [required],
      stmpPort: [required],
      account: [required],
      password: [required],
      senderName: [required],
      unsubscribeUrl: [
        required,
        { type: 'url', message: '请输入正确的URL地址' },
      ],
    });
    const sms = reactive({
      internationalMessageStatus: false,
      smsDisabled: false,
    });
    const smsForm = Form.useForm(sms, { id: [required], signer: [required] });
    const wechat = reactive({
      appId: '',
    });

    const wechatForm = ref();
    const initInfo = () =>
      enterpriseApi
        .getInfo(route.path, store.state.account.tenant)
        .then(data => {
          temp.info = data;
          Object.assign(info, { ...data });
        });
    const initEmail = () =>
      enterpriseApi.getEmail(route.path).then(data => {
        temp.email = data;
        Object.assign(email, { ...data });
      });
    const initSMS = () =>
      enterpriseApi.getSMS(route.path).then(data => {
        state.SMSChannelList.push(data);
        temp.sms = data;
        Object.assign(sms, { ...data });
      });
    const initWeChat = () =>
      enterpriseApi.getWeChat(route.path).then(data => {
        temp.wechat = data;
        Object.assign(wechat, { ...data });
      });
    const initSMSSignList = () =>
      enterpriseApi.getSMSSignList().then(data => (state.SMSSignList = data));
    const handleClickByBody = e => {
      if (!groupSelect.value.$el.contains(e.target)) {
        state.open = false;
      }
    };

    const templateRecordListState = reactive({
      recordLoading: true,
      recordList: [],
      templateMsg: {}, //当前点击查看模版
    });

    // 获取单条模版消息记录
    const getWechatAuthorizationListFun = record => {
      state.appId = record.appId;
    };

    const handleQueryAuthorization = record => {
      recordModalVisible.value = true;
      templateRecordListState.recordList = [];
      getWechatAuthorizationListFun(record);
    };

    initInfo();
    initEmail();
    initSMS();
    initWeChat();
    initSMSSignList();

    onMounted(() => {
      groupSelect.value.$el.addEventListener('click', e => {
        if (
          ['INPUT', 'SPAN', 'DIV'].includes(e.target.tagName) &&
          [
            'ant-select-selection-search-input',
            'ant-select-selection-item',
            'ant-select-selector',
          ].includes(e.target.className)
        ) {
          state.open = !state.open;
        }
      });
      document.body.addEventListener('click', handleClickByBody);
      // 查询国际短信是否开启
      enterpriseApi.getTenantContent().then(data => {
        if (data.internationalMessageStatus === 0) {
          sms.internationalMessageStatus = false;
        } else if (data.internationalMessageStatus === 1) {
          sms.internationalMessageStatus = true;
        }
        sms.smsDisabled = !data.smsPaymentStatus;
      });
    });

    onUnmounted(() =>
      document.body.removeEventListener('click', handleClickByBody)
    );

    return {
      columns: [
        {
          dataIndex: 'nickName',
          title: '公众号名称',
          width: 60,
          ellipsis: true,
          slots: { customRender: 'nickName' },
        },
        {
          dataIndex: 'isOpen',
          title: '默认',
          width: 25,
          ellipsis: true,
          slots: { customRender: 'isOpen' },
        },
        {
          dataIndex: 'xxxx',
          title: '已获权限',
          width: 25,
          ellipsis: true,
          slots: { customRender: 'queryAuthorization' },
        },
        {
          dataIndex: 'authorizationStatus',
          title: '状态',
          width: 25,
          ellipsis: true,
          slots: { customRender: 'authorizationStatus' },
        },
        {
          dataIndex: 'accountType',
          title: '类型',
          width: 25,
          ellipsis: true,
          slots: { customRender: 'accountType' },
        },
        {
          dataIndex: 'authenticated',
          title: '认证',
          width: 25,
          ellipsis: true,
          slots: { customRender: 'authenticated' },
        },
        {
          dataIndex: 'lastBindingTime',
          title: '绑定时间',
          width: 30,
        },
      ],
      recordColumns: [
        {
          dataIndex: 'name',
          title: '微信权限',
          width: 20,
          ellipsis: true,
          slots: { customRender: 'name' },
        },
        {
          dataIndex: 'enabled',
          title: '是否获取',
          width: 13,
          ellipsis: true,
          slots: { customRender: 'enabled' },
        },
        {
          dataIndex: 'desc',
          title: '权限内容',
          width: 60,
          slots: { customRender: 'desc' },
        },
      ],

      gridRef,
      urlModalPath: wechatConfig.queryAuthorization,
      urlRefreshAccountStatusPath: wechatConfig.refreshAccountStatus,
      urlPath: wechatConfig.pageUrl,
      recordModalVisible,
      visibleBind,
      groupSelect,
      groupInput,
      ...toRefs(state),
      ...toRefs(templateRecordListState),
      activeKey,
      info,
      infoRules: infoForm.validateInfos,
      email,
      emailRules: emailForm.validateInfos,
      sms,
      smsRules: smsForm.validateInfos,
      wechat,
      wechatForm,
      handleQueryAuthorization, // 查看配置三个之中的一个
      handleClickByAddGroup: () => {
        if (state.groupName) {
          enterpriseApi.addSMSSign({ signSms: state.groupName }).then(() => {
            initSMSSignList();
            state.groupName = '';
            groupInput.value.blur();
          });
        } else {
          groupInput.value.focus();
        }
      },
      handleClickByDeleteGroup: (e, id) => {
        e.stopPropagation();
        enterpriseApi.deleteSMSSign({ ids: [id] }).then(initSMSSignList);
      },
      handleClickByInfo: () =>
        infoForm.validate().then(() =>
          enterpriseApi
            .updateInfo('enterprise:updateinfo', toRaw(info))
            .then(() => {
              message.success('操作成功');
              initInfo();
            })
        ),
      handleClickByInfoReset: () => Object.assign(info, { ...temp.info }),
      handleClick: () => {
        switch (state.activeServeKey) {
          case 'email':
            emailForm.validate().then(() => {
              enterpriseApi
                .updateEmail('enterprise:updateinfo', toRaw(email))
                .then(() => {
                  message.success('操作成功');
                  initEmail();
                });
            });
            break;
          case 'sms':
            smsForm.validate().then(() => {
              const temp = state.SMSChannelList.find(i => i.id === sms.id);
              enterpriseApi
                .updateSMS('enterprise:updateinfo', {
                  ...temp,
                  signer: sms.signer,
                  isDefault: 1,
                })
                .then(() => {
                  message.success('操作成功');
                  initSMS();
                  let status = null;
                  if (sms.internationalMessageStatus) {
                    status = 1;
                  } else {
                    status = 0;
                  }
                  let parmas = {
                    internationalMessageStatus: status,
                  };
                  enterpriseApi.upDataStatus('', parmas).then(() => {});
                });
            });
            break;
          case 'wechat':
            wechatForm.value.validate().then(() => {
              enterpriseApi
                .updateWeChat('enterprise:updateinfo', toRaw(wechat))
                .then(() => {
                  message.success('操作成功');
                  initWeChat();
                });
            });
            break;
          default:
            break;
        }
      },
      handleClickByReset: () => {
        switch (state.activeServeKey) {
          case 'email':
            Object.assign(email, { ...temp.email });
            break;
          case 'sms':
            Object.assign(sms, { ...temp.sms });
            break;
          case 'wechat':
            Object.assign(wechat, { ...temp.wechat });
            break;
          default:
            break;
        }
      },
      // 设置为默认
      handleAuthorizationstatus(record) {
        wechatConfig.authorizationstatus('', { id: record.id }).then(res => {
          // 刷新
          if (res) {
            gridRef.value.refreshGrid();
            store.commit('account/setAppId', record.appId);
          }
        });
      },
      // 更新微信绑定
      updateWxFun() {
        state.updateLoading = true;
        wechatConfig.refreshAccountStatus('').then(res => {
          if (!res) {
            console.log(res);
          } else {
            state.updateLoading = false;
            message.success('同步成功');
          }
        });
      },

      // 绑定授权
      bindingWxFun() {
        visibleBind.value = true;
      },

      // 点击确定打开弹框
      handleOk() {
        visibleBind.value = false;
        wechatConfig.thirdPartyAuthorization('').then(res => {
          if (res) {
            window.open(
              `https://mp.weixin.qq.com/cgi-bin/componentloginpage?component_appid=${res.componentAppid}&pre_auth_code=${res.preAuthCode}&redirect_uri=${process.env.VUE_APP_VUE_TO_FORM}/#/system/enterprise&auth_type=3`
            );
          }
        });
      },
    };
  },
};
</script>

<style lang="less" scoped>
.iss-tab {
  :deep(.ant-tabs-bar) {
    margin-left: 16px;
    margin-right: 16px;
  }
}
.iss-button {
  margin-top: 100px;
}
.iss-form {
  padding-left: 20px;
  margin-left: -20px;
  max-height: calc(100vh - 177px);
  overflow-y: auto;
}
.sign-group {
  padding: 0 12px 0 10px;
  display: flex;
  align-items: center;
  .group-input {
    flex: 1;
  }
}
.font-color {
  color: #86909c;
}
.font-size {
  font-size: 12px;
  margin-top: 6px;
}
.uofficial_Account {
  .img-box {
    border: 1px solid #c9cdd4;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 8px;
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
}
.refresh-btn {
  cursor: pointer;
  display: flex; /* 使用Flexbox布局 */
  justify-content: flex-end; /* 内容左对齐 */
  align-items: center; /* 如果需要，可以在垂直方向上居中对齐 */
  padding: 10px; /* 内边距 */
  margin: 10px 0px 0px 0px;
}
.margin-right-50 {
  margin-right: 50px;
}
.center-content {
  display: flex; /* 启用Flexbox */
  align-items: center; /* 垂直居中 */
  font-size: 14px; /* 示例字体大小 */
  margin: 0 5px; /* 示例外边距 */
}
.modal-content-center {
  display: flex;
  flex-direction: column;
  justify-content: flex-end; /* 垂直居中 */
  align-items: center; /* 水平居中，如果不需要可以移除 */
  height: 100%; /* 确保模态框高度被充分利用 */
  margin: 0px 0px 2px 0px;
}

.modal-content-center > div {
  width: 100%; /* 可选，根据需求调整 */
  text-align: center; /* 文本居中 */
}
.cancle_sty {
  margin-right: 20px;
}
.ok_sty {
  margin-right: 165px;
}
.centered-content img {
  text-align: center;
}
</style>
